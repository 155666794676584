import React from "react";
import  "./second.scss";
import Fade from 'react-reveal/Fade';

export default () => {
    return(             
        <div className = "Secondcomponent component" id="about-us"> 
           <div className = "leftblock">
              
                <div className = "leftcaption">
                <Fade left>
                    <div className = "smalltitle">
                        <span className = "yhbold">THINK</span> 
                        <span className = "ahead ">AHEAD</span>
                    </div>
                </Fade>
                <Fade left>
                    <span className = "bigtitle">
                        <span className="yhbold">Who</span>
                        <span className = "we">We</span>
                        <span className="yhbold">Are</span>  
                    </span>
                </Fade> 
                </div>
              
                <Fade bottom>
                <div className = "leftcontent">
                    <div>
                        Established in November 2017, uFair is a fast-growing team made up of innovative and passionate youths.
                    </div>
                    <div>
                    By using information technology, we are dedicated to build an on-campus online community at the University of Nottingham Ningbo China (UNNC) to enhance student’s college experience, and make all UNNCers closely connected through our online applications and products.
                    </div>
                </div>
                </Fade>
           </div>
 
           {/* <Fade right> */}
           <div className = "rightblock">
                 <div className = "backgroundcolor"></div>
                 <div className = "backgroundIMG">
                 </div>
                 
                 <div className = "rightcaption">
                 {/* <Fade right> */}
                     <div className = "righttitle yhbold">
                         ENHANCE
                     </div>
                 {/* </Fade> */}
                 {/* <Fade right> */}
                     <div className = "rightnote yhbold">
                         <p> the college experience of UNNC students</p>
                         <p>through Internet service</p>
                     </div>
                     {/* </Fade> */}
                 </div>
                 
                 
           </div>
           {/* </Fade> */}
        </div>
    )
}
