import React from 'react';
import logo from './ufair-logo.png';
import './App.css';
import Comp1 from "./Comp1/Comp1";
import Comp2 from "./Comp2/Comp2";
import Comp3 from "./Comp3/Comp3";
import Comp4 from "./Comp4/Comp4";
import Comp5 from "./Comp5/Comp5";
import Comp6 from "./Comp6/Comp6";
import Course from "./Comp5/Course";
import Map from "./Comp5/Map";
import { BrowserRouter, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import ScrollToTop from 'react-router-scroll-top'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollableSection from 'react-update-url-on-scroll';
import ResponsiveMenu from 'react-responsive-navbar';
import { AiOutlineClose, AiOutlineBars } from "react-icons/ai";

const pageNames = ['HOME', 'ABOUT US', 'SERVICES', 'VISION', 'HISTORY', 'CONTACTS'];
const anchor = ['home', 'about-us', 'services', 'vision', 'history', 'contacts'];

const Page = ({ children }) => {
  let comps = React.Children.toArray(children);
  let newComps = comps.map((page, index) => (
    React.createElement(
      ScrollableSection, 
      {
        name: `#${anchor[index]}`,
        key: index
      }, 
      React.createElement(
        'div', 
        {
          className: 'page-comp',  
        },
        React.cloneElement(page)
      )
    )
  ));
  return (<div>{newComps}</div>);
}

class FullPage extends React.Component {

  Home = () => {
    const pages = pageNames.map((page, index) => 
        (<li key={index}>
          <AnchorLink href={`#${anchor[index]}`}>
            {page}
          </AnchorLink>
        </li>)
    )
    return (
      <div>
        <Page>
          <Comp1 />
          <Comp2 />
          <Comp5 />
          <Comp3 />
          <Comp6 />
          <Comp4 />
        </Page>

        <div id="header">
          <div className="logo-container">
            <img className="logo" src={logo} alt="ufair logo"/>
          </div>

          <ResponsiveMenu
            className="pager"
            menuOpenButton={<AiOutlineBars size={30} color="Orange" />}
            menuCloseButton={<AiOutlineClose size={30} color="Orange" />}
            changeMenuOn="960px"
            largeMenuClassName="pager"
            smallMenuClassName="small-menu"
            menu={
              <div>
                {pages}
              </div>}
          />
        </div>
        
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
        <ScrollToTop>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 1 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route exact path="/" component={this.Home} />
            <Route exact path={'/ucourse'} component={Course} />
            <Route exact path={'/umap'} component={Map} />
          </AnimatedSwitch>
          </ScrollToTop>
        </BrowserRouter>
      </React.Fragment>
	 
    );
  }
}


export default FullPage;