import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import React from "react";
import "./sixth.css"
import arrow from "./arrow.svg"

export default () => {
  return (
    <div id="history" className="component">
        <ScrollAnimation animateIn="bounceInBottom" animateOut="bounceOutTop" animateOnce>
        <div className="left-words">
            <span className="black-big-word"> Create<br /> </span>
            <span className="orange-big-word"> History </span>
            <h4 className="small-words">
                <span> THINK </span>
                <span className="orange"> AHEAD </span>
            </h4>
        </div>
        </ScrollAnimation>
        
        <div className="bgimage">
            
            <div className="mask">
            <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRigh" className="mask2" animateOnce>

                <div className="total201711">
                    <img src={arrow} alt="arrow" className="arrow" />
                    <span className="title"> Nov.&nbsp;2017 </span><br />
                    <div className="detail">uFair&nbsp;was<br />established&nbsp;by&nbsp;4<br />UNNC&nbsp;students</div>
                </div>
                {/* </ScrollAnimation> */}

                {/* <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRigh">  */}
                <div className="total20189">
                    <img src={arrow} alt="arrow" className="arrow" />
                    <span className="title"> Sept.&nbsp;2018 </span><br />
                    <div className="detail"> Officially&nbsp;released&nbsp;of<br />uCourse,&nbsp;our&nbsp;first<br />mobile&nbsp;APP</div> 
                </div>
                {/* </ScrollAnimation> */}

                {/* <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRigh"> */}
                <div className="total20183">
                    <img src={arrow} alt="arrow" className="arrow" />
                    <span className="title"> Mar.&nbsp;2018 </span><br />
                    <div className="detail"> Alpha&nbsp;test&nbsp;of<br />uSquare,&nbsp;our&nbsp;first<br />internal&nbsp;project</div>
                </div>
                {/* </ScrollAnimation> */}

                {/* <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRigh"> */}
                <div className="total20199">
                    <img src={arrow} alt="arrow" className="arrow" />
                    <span className="title"> Sept.&nbsp;2019 </span><br />
                    <div className="detail"> Officially&nbsp;released&nbsp;of<br />uMap (UNNCMap),&nbsp;our<br />second&nbsp;mobile&nbsp;APP</div>    
                </div>
                </ScrollAnimation>
            </div>
        </div>        
    </div>
  );
};
