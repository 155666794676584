import React from "react";
import "./product.css"
import { HashLink } from 'react-router-hash-link';

const Product = ({ name, logo, code, intro }) => {
    return (
        <div className="component product">
        <HashLink to="/#services" className="back">Back</HashLink>
        <div className="content-container">
            <div className="container-two">
                <div className="logo-container">
                    <img src={logo} alt={`${name} logo`} className="logo" />
                    <h1 className="title">{name}</h1>
                </div>
                <div className="intro">
                    {intro.map((para, index) => (<p key={index}>{para}</p>))}
                </div>
            </div>
            <img src={code} alt={`${name} qr code`} className="code" />
        </div>
    </div>
    )
}

export default Product;