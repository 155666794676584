import React from "react";
import umap from "./umap.png"
import umapCode from "./umap-code.png"
import Product from "./Product"

export default () => {
    const intro = [
        'uMap is a real-time interactive university map aimed at helping freshmen adapt to the university environment.', 
        'It can enhance their campus tour experience by providing an online tour accorded with the offline one. Supported by the automatic positioning function of uMap, players can answer various questions related to the different university buildings to win souvenirs, which could help them get familiar with campus buildings in a most entertaining way.'
    ]

  return (
   <Product name='uMap' logo={umap} code={umapCode} intro={intro} />
  );
};
