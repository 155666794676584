import React from "react";
import "./third.css";
import light from './light.png';
import computer from './computer.png';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default () => {
  return(

    <div id="vision" className = "component">

      
        <div className = "topbox"> 
          <ScrollAnimation animateIn="bounceInUp" animateOut="bounceOutUp" duration={1.5} animateOnce>
              <span className = "think"> THINK </span>
              <span className = "ahead"> AHEAD </span>
              <h1 className = "vision"> Vision </h1>
              <span className = "text_1"> To be the best online campus service provider in China. </span>
              <span className = "text_2"> To be the best online <br /> campus service <br /> provider in China. </span>
          </ScrollAnimation>
        </div>
      

        <div className = "bottombox"> 
          <div className = "mask"> </div>

          <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft' duration={1.5} animateOnce>
            <div className = "innovation">
              <img className = "light" src = {light} alt = "light" />
              <h2 className = "word"> INNOVATION </h2>
              <h3 className = "in"> To be the best in Innovation </h3> <br />
              <span className = "i"> The best team that supports </span> <br />
              <span className = "i"> personal achievements and </span> <br />
              <span className = "i"> motivates members to </span> <br />
              <span className = "i"> create innovation. </span>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutRight' duration={1.5} animateOnce>
            <div className = "technology">
                <img className = "computer" src = {computer} alt = "computer" />
                <h2 className = "word"> TECHNOLOGY </h2>
                <h3 className = "in"> To be the best in Technology </h3> <br />
                <span className = "i"> The best service provider to </span> <br />
                <span className = "i"> enhance user experience by </span> <br />
                <span className = "i"> taking advantage of the </span> <br />
                <span className = "i"> latest technology. </span>
            </div>
          </ScrollAnimation>

        </div>

    </div>

  );
};
