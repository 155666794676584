import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import React from "react";
import "./fourth.css"
import logo from "../ufair-logo.png"
import email from "./email.png"
import locate from "./location.png"
// import {Typography } from 'antd';
// const { Text } = Typography;

/*
class Comp4 extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			show:true
		}
		//this.resize.bind(this);
	//this.handlethispage = this.handlethispage.bind(this);
	}



	componentDidMount() {
		window.addEventListener('resize', this.resize)
	}
	
	componentWillUnmount() {       
		window.removeEventListener('resize',this.resize);
	}

	componentWillReceiveProps(nextProps){
		const { customPageNumber } = nextProps
		//console.log(currentPage);
		if (customPageNumber === 4) {
			this.setState({
				show:true
			})
		}
		else{
			this.setState({
				show:false
			})
		}
	}
	
	
	render(){

		//document.addEventListener(visibilityChangeEvent, onVisibilityChange)
		//let count = this.props.currentPage;
		
		const boxup_xl=(
			<div className="borderposition_xl">
				<h3 className= {this.state.show ? 'border_xl fadeInUpBig animated':'border_xl fadeOutDownBig animated'}>OUR CONTACTS</h3>
			</div>
		);
		const boxup_lg=(
			<div className="borderposition_lg">
				<h3 className= {this.state.show ? 'border_lg fadeInUpBig animated':'border_lg fadeOutDownBig animated'}>OUR CONTACTS</h3>
			</div>
		);
		const boxup_md=(
			<div className="borderposition_md">
				<h3 className= {this.state.show ? 'border_md fadeInUpBig animated':'border_md fadeOutDownBig animated'}>OUR CONTACTS</h3>
			</div>
		);
		const boxup_sm=(
			<div className="borderposition_sm">
				<h3 className= {this.state.show ? 'border_sm fadeInUpBig animated':'border_sm fadeOutDownBig animated'}>CONTACTS</h3>
			</div>
		);
		
		const boxup_xs=(
			<div className="borderposition_xs">
				<h3 className= {this.state.show ? 'border_xs fadeInUpBig animated':'border_xs fadeOutDownBig animated'}>CONTACTS</h3>
			</div>
		);
		
		const boxleft_xl=(
			<a className={this.state.show ?'boxleft_xl fadeInLeftBig animated':'boxleft_xl fadeOutRightBig animated'} target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
				<img className="email" src={email} alt='email'/>
				<div className="spanpostion">
					<div className="boxleftspan_xl"><Text copyable>uFair-UNNC@outlook.com</Text></div>
				</div>
			</a>
		);
		const boxleft_lg=(
			<a className={this.state.show ?'boxleft_lg fadeInLeftBig animated':'boxleft_lg fadeOutRightBig animated'} target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
				<img className="email" src={email} alt='email'/>
				<div className="spanpostion">
					<div className="boxleftspan_lg" ><Text copyable>uFair-UNNC@outlook.com</Text></div>
				</div>
			</a>
		);
		const boxleft_md=(
			<a className={this.state.show ?'boxleft_md fadeInLeftBig animated':'boxleft_md fadeOutRightBig animated'} target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
				<img className="email" src={email} alt='email'/>
				<div className="spanpostion">
					<div className="boxleftspan_md" ><Text copyable>uFair-UNNC@outlook.com</Text></div>
				</div>
			</a>
		);
		const boxleft_sm=(
			<a className={this.state.show ?'boxleft_sm fadeInLeftBig animated':'boxleft_sm fadeOutRightBig animated'} target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
				<img className="email" src={email} alt='email'/>
				<div className="spanpostion">
					<div className="boxleftspan_sm" ><Text copyable>uFair-UNNC@outlook.com</Text></div>
				</div>
			</a>
		);
		const boxleft_xs=(
			<a className={this.state.show ?'boxleft_xs fadeInLeftBig animated':'boxleft_xs fadeOutRightBig animated'} target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
				<div className="spanpostion_xs">
					<div className="boxleftspan_xs" ><Text copyable>uFair-UNNC@outlook.com</Text></div>
				</div>
			</a>
		);


		const boxright_xl=(
			<a className={this.state.show ?'boxright_xl fadeInRightBig animated':'boxright_xl fadeOutLeftBig animated'} target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
				<img className="locate" src={locate} alt='locate'/>
				<div className="spanpostion">
					<div className="boxrightspan_xl" ><Text copyable>199 Taikang East Road Ningbo, China, 315100</Text></div>
				</div>
			</a>
		);
		const boxright_lg=(
			<a className={this.state.show ?'boxright_lg fadeInRightBig animated':'boxright_lg fadeOutLeftBig animated'} target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
				<img className="locate" src={locate} alt='locate'/>
				<div className="spanpostion">
					<div className="boxrightspan_lg"><Text copyable>199 Taikang East Road Ningbo, China, 315100</Text></div>
				</div>
			</a>
		);
		const boxright_md=(
			<a className={this.state.show ?'boxright_md fadeInRightBig animated':'boxright_md fadeOutLeftBig animated'} target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
				<img className="locate" src={locate} alt='locate'/>
				<div className="spanpostion">
					<div className="boxrightspan_md"><Text copyable>199 Taikang East Road Ningbo, China, 315100</Text></div>
				</div>
			</a>
		);
		const boxright_sm=(
			<a className={this.state.show ?'boxright_sm fadeInRightBig animated':'boxright_sm fadeOutLeftBig animated'} target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
				<img className="locate" src={locate} alt='locate'/>
				<div className="spanpostion">
					<div className="boxrightspan_sm"><Text copyable>199 Taikang East Road Ningbo, China, 315100</Text></div>
				</div>
			</a>
		);
		const boxright_xs=(
			<a className={this.state.show ?'boxright_xs fadeInRightBig animated':'boxright_xs fadeOutLeftBig animated'} target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
			<div className="spanpostion_xs">
				<div className="boxrightspan_xs"//contentEditable="true"><Text copyable>199 Taikang East Road Ningbo, China, 315100</Text></div>
			</div>
			</a>
		);
		
		
		const foot=(
			<div className='foot'>
				<div className="footerposition">
					<h5 className="footer">Created by UFair, Copyright 2019, 备案号：浙 ICP备20002118号-1</h5>
				</div>
				<a href='/' >
					<img className="footimage" src={logo} alt='logo' />
				</a>
			</div>
		);
		
		
		return (
			<div id="four" className="component">
			<Media queries={{
			    xs: "(max-width: 576px)",
				sm: "(min-width: 577px) and (max-width: 768px)",
			    md: "(min-width: 768px) and (max-width: 991px)",
				lg: "(min-width: 992px) and (max-width: 1199px)",
			    xl: "(min-width: 1200px)"
			}}>
			{matches => (
			<React.Fragment>
				{matches.xl && boxup_xl}
				{matches.lg && boxup_lg}
				{matches.md && boxup_md}
				{matches.sm && boxup_sm}
				{matches.xs && boxup_xs}
				
				{matches.xl && boxleft_xl}
				{matches.lg && boxleft_lg}
				{matches.md && boxleft_md}
				{matches.sm && boxleft_sm}
				{matches.xs && boxleft_xs}
				
				{matches.xl && boxright_xl}
				{matches.lg && boxright_lg}
				{matches.md && boxright_md}
				{matches.sm && boxright_sm}
				{matches.xs && boxright_xs}
				
				{foot}
			</React.Fragment>
			)}
			</Media>
			</div>
		);
	}
}
export default Comp4;
*/

export default () => {
	return (
	<div id = "contacts"  className="component">
		
		<div className="borderposition">
			<h3 className= "border">OUR CONTACTS</h3>
		</div>
		
		<div className="boxcontainer">
			<ScrollAnimation animateIn="fadeInLeftBig" animateOut='fadeOutDownBig' duration={1.5} animatePreScroll={false} className="left" animateOnce>
				<a className="boxleft fadeInLeftBig animated" target="_blank" rel="noopener noreferrer" href="mailto:uFair-UNNC@outlook.com">
					<img className="email" src={email} alt='email'/>
					<div className="spanpostion">
						<div className="boxleftspan" contentEditable="true">uFair-UNNC@outlook.com</div>
					</div>
				</a>
			</ScrollAnimation>
		
			<ScrollAnimation animateIn="fadeInRightBig" animateOut='fadeOutDownBig' duration={1.5} animatePreScroll={false} className="right" animateOnce>
				<a className="boxright fadeInRightBig animated" target="_blank" rel="noopener noreferrer" href='https://map.baidu.com/poi/宁波诺丁汉大学/@13533120.77514097,3458137.058488815,14.13z?uid=dffd464286316db33c9a5e65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl'>
					<img className="locate" src={locate} alt='locate'/>
					<div className="spanpostion">
						<div className="boxrightspan" contentEditable="true">199 Taikang East Road Ningbo, China, 315100</div>
					</div>
				</a>
			</ScrollAnimation>
		</div>
		
		<div className='foot'>
			<div className="footerposition">
				<h5 className="footer">Created by uFair, Copyright 2019, 备案号：浙 ICP备20002118号-1</h5>
			</div>
			<a href='/' >
				<img className="footimage" src={logo} alt='logo' />
			</a>
		</div>
	</div>
	);
};
