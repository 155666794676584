import React from "react";
import ucourse from "./ucourse.png"
import ucourseCode from "./ucourse-code.jpg"
import Product from "./Product"

export default () => {
    const intro = [
        "uCourse is a platform for course evaluation based on WeChat mini program. Gathering information on official website we have built a comprehensive overview of every single module in UNNC.",
        "Apart from providing highly customised timetable for our students, we also encourage them to share views on their course modules for peer reference. Various functions are provided to maximise the quality of course reviews, including star rating through three dimensions (course design/ teaching quality/ level of difficulty), comment/ like interaction, to make this platform an efficient place for communicating academic experience. ",
        "Users can not only search course reviews by faculty, major or grade, but also develop a person profile on the reviews made by themselves."
    ];

  return (
    <Product name="uCourse" intro={intro} logo={ucourse} code={ucourseCode} />
  );
};
