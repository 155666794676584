import React from "react";
import "./first.css";
import logo from './ufair-logo-crop.png';
// import logo from './logo.svg';
import background from './background.png';
import ScrollAnimation from 'react-animate-on-scroll';
// import Fade from 'react-reveal/Fade';
// import styled, { keyframes } from 'styled-components';
// import { bounce } from 'react-animations';
 
// const bounceAnimation = keyframes`${bounce}`;
 
// const BouncyDiv = styled.div`
//   animation: 1s ${bounceAnimation};
// `;

import "animate.css/animate.min.css";

export default () => {
  return (
    <div id="home" className="component">
      <img src={background} alt="background" className="background"/>

      <div className="content-container">
        {/* <Fade top> */}
        {/* <BouncyDiv> */}
        <ScrollAnimation animateIn="fadeIn" duration={1.5} initiallyVisible animateOnce>
        <div className="unlock-container">
          <h1>uFair</h1>
          <h1 className="unlock">Unlock Your <span className="orange">UNNC</span> Life</h1>
        </div>

        <div className="large-logo-container">
          <img src={logo} alt="logo" className="large-logo"/>
          <h1 className="slogan">
            <span>THINK </span>
            <span className="orange">AHEAD</span>
          </h1>
          <div className="shadow-container"></div>
        </div>
        {/* </BouncyDiv> */}
        </ScrollAnimation>
        {/* </Fade> */}

      </div>
      


    </div>
  );
};
