import React from "react";
import "./fifth.css"
import ucourse from "./ucourse.png"
import umap from "./umap.png"
import { Link } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';


class Card extends React.Component {
  render() {
    return (
      <div className="card">
        <div className="card-content">
          <img src={this.props.img} alt="ucourse logo" className="logo"/>
          <h1>{this.props.name}</h1>
          <Link to={this.props.link} className="more">more</Link>
        </div>
      </div>
    )
  }
}

export default () => {
  return (
    <div id="services" className="component">
      <div className="main">
        <h2 className="title">Services</h2>
        <h3 className="slogan">THINK <span className="ahead">AHEAD</span></h3>
        <div className="card-container">
          <ScrollAnimation animateIn="fadeIn"  duration={1.5} animateOnce>
            <Card img={ucourse} name="uCourse" link="/ucourse"/>
          </ ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn"  duration={1.5} animateOnce>
            <Card img={umap} name="uMap" link="/umap"/>
          </ ScrollAnimation>

        </div>
      </div>
    </div>
  );
};
